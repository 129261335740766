<template>
  <div>
    <div class="row" style="margin: 5px">
      <div class="col-md-12 col-12">
        <h2 class="font-weight-bold">
          {{ $t("createTranferOut") }}
        </h2>
      </div>
    </div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm" style="border-radius: 0.1rem">
        <CCardBody>
          <CAlert id="transferOutSuccessAlert" color="success" v-if="isSuccess === true">
            {{ $t("dialogSaveTransferDocumentSuccess") }}
          </CAlert>
          <CAlert id="transferOutValidationError" color="danger" v-if="validatedata != ''">{{ validatedata }}</CAlert>
          <CAlert id="transferOutSaveSuccess" color="success" v-if="isSaveSuccess != ''">
            {{ isSaveSuccess }}
          </CAlert>
          <div class="row">
            <div class="col-md-12 col-12">
              <h4 class="font-weight-bold">
                {{ $t("information") }}
              </h4>
            </div>
          </div>
          <br />
          <CRow>
            <CCol lg="6" col="12">
              <CRow class="form-group row">
                <label class="col-4 col-form-label">
                  {{ $t("sourceBranch") }}
                </label>
                <div class="col-8">
                  <select id="transferOutSourceBranch" class="custom-select" v-model="shopObjectId" disabled>
                    <option
                      v-for="shop in shops"
                      :key="shop.objectId"
                      :value="shop.objectId"
                    >
                      {{ shop.shopName }} - {{ shop.branchName }}
                    </option>
                  </select>
                </div>
              </CRow>
            </CCol>
            <CCol lg="6" col="12">
              <CRow class="form-group row">
                <label class="col-4 col-form-label">
                  {{ $t("destinationShopBranch") }}
                </label>
                <div class="col-8">
                  <select
                    class="custom-select"
                    v-model="destinationShop"
                    id="transferOutDestinationBranch"
                  >
                    <option value="" selected>{{ $t("selectshop") }}</option>
                    <option
                      v-for="shop in destinationShopData"
                      :key="shop.objectId"
                      :value="shop.objectId"
                    >
                      {{ shop.shopName }} - {{ shop.branchName }}
                    </option>
                  </select>
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6" col="12">
              <CRow class="form-group row">
                <label class="col-4 col-form-label">
                  {{ $t("createdAt") }}
                </label>
                <div class="col-8">
                  <VueDatePicker
                    disabled
                    id="transferOutCreatedAt"
                    color="#29B46B"
                    :locale="getDateLocale()"
                    :visible-years-number="90"
                    v-model="datetime"
                    format="DD-MM-YYYY"
                    class="form-control text-dark"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" />
                    </template>
                  </VueDatePicker>
                </div>
              </CRow>
            </CCol>
            <CCol lg="6" col="12">
              <CRow class="form-group row">
                <label class="col-4 col-form-label">
                  {{ $t("dueDate") }}
                </label>
                <div class="col-8">
                  <VueDatePicker
                    id="transferOutDueDate"
                    color="#29B46B"
                    :locale="getDateLocale()"
                    :visible-years-number="90"
                    v-model="dueDate"
                    format="DD-MM-YYYY"
                    :min-date="minDate"
                    class="form-control text-dark"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" />
                    </template>
                  </VueDatePicker>
                </div>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard class="shadow-sm" style="border-radius: 0.1rem">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8">
              <h4 class="font-weight-bold">
                {{ $t("productTransferOut") }}
              </h4>
              <p>
                {{ $t("transferOutdescrip") }}
              </p>
            </div>
          </div>
          <br />
          <CRow>
            <label class="col-1 col-form-label text-center text-dark">
              {{ $t("search") }}
            </label>
            <CCol col="8">
              <Multiselect
                id="transferOutSearchProduct"
                class="text-dark"
                style="height: 35px"
                v-model="keywordEntered"
                :placeholder="$t('searchProduct')"
                :options="dataProduct"
                @input="onSelectedAutoCompleteEvent"
                :custom-label="customLabel"
                :showLabels="false"
                :clear-on-select="false"
                :close-on-select="true"
                :search="true"
              >
              <template #option="props">
                <span class="category-name">{{ props.option.category.name }}</span> - 
                {{ props.option.PLUCode }} - {{ props.option.name }}
              </template>
              <template #singleLabel="props">
                <span class="category-name">{{ props.option.category.name }}</span> - 
                {{ props.option.PLUCode }} - {{ props.option.name }}
              </template>
            </Multiselect>              
            </CCol>
            <CCol col="3">
              <CRow >
                  <CButton id="transferOutAddProduct" block color="success" v-on:click="onCheckboxChange" :disabled="shopObjectId === destinationShop">
                    {{ $t("addProduct") }}
                  </CButton>
              </CRow>
            </CCol>
          </CRow>

          <CRow v-if="items && items.length != 0">
            <CCol sm="12" lg="12"> </CCol>
          </CRow>
          <CRow v-if="items && items.length != 0">
            <CCol sm="12" lg="12">
              <br v-if="items && items.length != 0" />
              <div style="overflow-x: auto">
                <p id="transferOutDuplicateItem" v-if="duplicateItem != ''" class="text-danger">
                  {{ duplicateItem }}
                </p>
                <table
                  class="table table-responsive table-bordered text-dark"
                  v-if="items.length > 0"
                  style="min-width: max-content"
                  id="transferOutProductTable"
                >
                  <thead>
                    <tr>
                      <th style="width: 2%" class="text-center text-dark">
                        {{ $t("number") }}
                      </th>
                      <th class="text-dark">{{ $t("pluCode") }}</th>
                      <th class="text-dark">{{ $t("productName") }}</th>
                      <th class="text-dark" style="width: 8%">{{ $t("unit") }}</th>
                      <th class="text-right text-dark">{{ $t("shopQty") }}</th>
                      <th class="text-right text-dark">{{ $t("transferQty") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody style="background-color: #ffffff">
                    <tr v-for="(item, index) in items" :key="index"class="text-dark" :id="'transferOutRow' + index">
                      <td class="text-center " :value="(item.orderIndex = index)">
                        <div style="margin-top: 6px">
                          {{ index + 1 }}
                        </div>
                      </td>
                      <td :id="'transferOutPLUCode' + index">
                        <div style="margin-top: 6px">
                          {{ item.productPLU.PLUCode }}
                        </div>
                      </td>
                      <td :id="'transferOutProductName' + index">
                        <div style="margin-top: 6px">
                          {{ item.productPLU.name }}
                        </div>
                      </td>
                      <td>
                        <div style="margin-top: 6px">1 / {{ item.productPLU.unit.name }}</div>
                      </td>
                      <p style="display: none">
                        {{
                          (calculatePrevious =
                              (Number(item.previousOnhand) - Number(item.quantity)).toFixed(4))
                        }}
                      </p>
                      <td style="width: 15%" v-if="calculatePrevious >= 0">
                        <input
                          class="form-control form-control-md text-right"
                          :value="calculatePrevious"
                          disabled
                          :id="'transferOutShopQty' + index"
                        />
                      </td>
                      <td style="width: 15%" v-else-if="calculatePrevious <= 0">
                        <input
                          class="form-control form-control-md text-right text-danger"
                          :value="calculatePrevious"
                          disabled
                          :id="'transferOutShopQtyDanger' + index"
                        />
                      </td>
                      <td
                        style="width: 15%"
                        v-else-if="isNaN(calculatePrevious) === true"
                      >
                        <input
                          class="form-control form-control-md text-right text-success"
                          :value="0"
                          disabled
                        />
                      </td>
                      <td style="width: 10%">
                        <input
                          type="number"
                          min="0"
                          class="form-control form-control-md text-right text-dark"
                          v-model.number="item.quantity"
                          :id="'transferOutQuantity' + index"
                        />
                        <p
                          class="text-danger"
                          v-if="Number(item.quantity < 0) || Number.isNaN(item.quantity)"
                        >
                          {{ $t("dialogfound") }}
                        </p>
                      </td>
                      <td class="text-center" style="width: 2%">
                        <CButton color="danger" :id="'transferOutRemoveProduct_' + index" size="sm" @click="removeProduct(item)">
                          <CIcon name="cil-trash"></CIcon>
                        </CButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <div v-if="validateItem" class="text-danger">{{ validateItem }}</div>
          <br v-if="items.length > 0" />
          <CRow v-if="items.length > 0">
            <CCol sm="12" lg="12">
              <CTextarea
                id="transferOutNote"
                v-model="note"
                :label="$t('reason')"
                :placeholder="$t('reason')"
                rows="3"
              />
            </CCol>
          </CRow>
          <br v-if="items.length > 0" />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol class="่col-12 d-flex justify-content-end mb-3">
        <CButton
          id="transferOutCancelButton"
          class="btn col-3"
          style="background-color: white; font-weight: bold; margin-right: 15px"
          @click="$router.go(-1)"
        >
          {{ $t("cancel") }}
        </CButton>
        
        <CButton
          id="transferOutValidateButton"
          color="success"
          class="col-3"
          @click="validateDoc"
          v-if="items.length != 0"
        >
          {{ $t("transfer") }}
        </CButton>
        <CButton id="transferOutDisabledButton" color="success" class="col-3" v-else disabled>
          {{ $t("transfer") }}
        </CButton>
      <br />
    </CCol>
    <CRow>
      <CModal
        id="transferOutOnhandIncorrectModal"
        :show.sync="onhandIncorrectModal"
        color="success"
        :title="$t('ProductNotEnought')"
        centered
        :footer="footer"
      >
        <br />
        <h4 class="text-center">
          {{ $t("checkStockPLUMsg") }}
        </h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="12">
              <CButton id="transferOutCancelButton" block color="light" @click="onhandIncorrectModal = false">
                {{ $t("cancel") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>
    <CRow>
      <CModal
        :show.sync="invalidTransferModal"
        color="warning"
        :title="$t('transferDocumentCreationFailed')"
        centered
        :footer="footer"
        id="transferOutInvalidModal"
      >
        <br />
        <h4 id="transferOutErrorMessage" class="text-center">
          {{this.message}}
        </h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="6">
              <CButton id="transferOutSubmitButton" block color="light" @click="invalidTransferModal = false">
                {{ $t("submit") }}
              </CButton>
            </CCol>
            <CCol col="6">
              <CButton id="transferOutCancelButton" block color="light" @click="$router.push('/inventory/transferOut')">
                {{ $t("cancel") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>
    <CModal
      :show.sync="confirmSave"
      centered
      :title="$t('confirmSaveDoc')"
      color="success"
      header="false"
      id="transferOutConfirmModal"
    >
    <br>
      <CRow class="justify-content-center col-md-12" v-if="loadingButton === false">
        <h4 class="text-center">
          {{ $t("confirmSaveTransfer") }}
        </h4>
      </CRow>
      
      <div id="transferOutSavingState" style="text-align: center" v-if="loadingButton === true">
        <CSpinner color="success" size="lg" class="text-success" centered />
        <br />
        <br />
        <h4 class="text-success">{{ $t("savingDoc") }}</h4>
        .
      </div>
      <template #footer>
        <CRow class="justify-content-around col-md-12">
          <CCol col="4" v-if="loadingButton === false">
            <CButton id="transferOutPendingButton" @click="saveDocument()" color="outline-success"  block>
              <b>{{ $t("pendingTransfer") }}</b>
            </CButton>
          </CCol>
          <CCol col="4" v-if="loadingButton === false">
            <CButton id="transferOutApproveButton" @click="saveDocumentStatusA()" color="success" block>
              <b>{{ $t("ApproveTransfer") }}</b>
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import local from "../../../services/local";
import util from "../../../util/util";
import Multiselect from "vue-multiselect";
import axios from "@/services/local";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      isSuccess: false,
      alert: false,
      data: [],
      quantity: 0,
      dataProduct: [],
      datetime: new Date(),
      shop: [],
      inventory: [],
      branch: [],
      isVatIncluded: true,
      note: "",
      discount: 0,
      searchBy: "SKUName",
      keyword: "",
      items: [],
      validateShop: "",
      validateItem: "",
      loadingButton: false,
      datalist: [],
      duplicateItem: "",
      destinationShop: '',
      // destinationShopData: [],
      destinationShopDetail: "",
      validatedata: "",
      isSaveSuccess: "",
      confirmSave: false,
      AddProductModal: false,
      keywordEntered: "",
      selectedItems: [],
      all: false,
      footer: "",
      validateProduct: "",
      currentAlertCounter: 0,
      autoCompleteResult: [],
      Items: [],
      selectedItems: [],
      onhandIncorrectModal: false,
      invalidTransferModal: false,
      message: [],
      dueDate: new Date(),
      minDate: this.getMinDate()
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date", "permissions"]),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    shopdetail() {
      return this.shops.find((i) => i.objectId === this.shopObjectId);
    },
    destinationShopData() {
      if (!this.shopdetail) {
        return [];
      }

      let detail = [];
      this.shops.forEach((shop) => {
        if (shop.uid === this.shopdetail.uid) {
          detail.push({
            shopName: shop.shopName,
            branchName: shop.branchName,
            id: shop.id,
            objectId: shop.objectId,
            uid: shop.uid,
          });
        }
      });
      return detail;
    },
    list() {
      let data = this.dataProduct;
      let detail = [];
      for (let i = 0; i < data.length; i++) {
        let PLUCode = "";
        let stdCost = 0;
        let price = 0;
        let category = ''
        if (data[i].category === undefined) {
          category = '-'
        } else {
          category = data[i].category.name || '-'
        }
        if (data[i].ProductSKU.stdCost === undefined) {
          stdCost = 0;
        } else {
          stdCost = data[i].ProductSKU.stdCost;
        }

        if (data[i].PLUCode === undefined) {
          PLUCode = "-";
        } else {
          PLUCode = data[i].PLUCode;
        }
        detail.push({
          id: data[i].id,
          object: data[i].objectId,
          discount: 0,
          total: 0,
          quantity: this.quantity,
          orderIndex: i,
          index: i,
          SKUName: data[i].ProductSKU.SKUName,
          price: price,
          PLUCode: PLUCode,
          ProductPLUStock: data[i].onhandQty,
          productPLUId: data[i].ProductPLU.id,
          ProductSKU: data[i].ProductSKU,
          ProductPLU: {
            id: data[i].id,
            objectId: data[i].objectId,
            name: data[i].name,
          },
          name: data[i].name,
          stdCost: stdCost,
          unit: data[i].unit,
          unitName: data[i].unitName,
          uid: data[i].uid,
          remoteImagePath: data[i].remoteImagePath,
          indexColor: util.generateColor(data[i].indexColor),
          selected: data[i].selected,
          category: category
        });
      }
      return detail;
    },
  },
  created() {
    this.searchProductPLU()
    this.setAddress()
    // this.getDestination()
  },
  mounted() {
    this.$store.dispatch("getShop")
    this.$store.dispatch("getUser")
  },
  watch: {
    destinationShop(newVal) {
      this.setAddress();
    },
    validatedata(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.validatedata = '';
        }, 10000);
      }
    },
    isSuccess(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.isSuccess = false;
        }, 10000);
      }
    },
    isSaveSuccess(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.isSaveSuccess = '';
        }, 10000);
      }
    }
  },
  methods: {
    ...util,
    onMouseOver(event) {
      event.target.style.color = 'white'; 
    },
    onMouseLeave(event) {
      event.target.style.color = '#3abc98';
    },
    async getDestination() {
      this.validateShop = "";
      const uid = `${localStorage.getItem("shopsUid")}`;
      let params = {
        shopObjectId: this.shopObjectId,
      };
      const headers = { shopObjectId: this.shopObjectId};
      try {
        const response = await axios({
          url: `/api/v1.0/${uid}/Shop/list/sibling`,
          params: params,
          headers: headers,
          method: "GET",
        });
        this.destinationShopData = response.data.data
      } catch (e) {
        this.alert = true;
        console.error(e);
        return false;
      }
    },
    async checkItemDestination(item) {
      if (!this.shopObjectId) {
        this.validateShop = this.$i18n.t("selectShopPlease");
        return false;
      }

      this.validateShop = "";
      const uid = `${localStorage.getItem("shopsUid")}`;
      let params = {
        shopObjectId: this.destinationShopDetail.objectId,
        id: item.id
      };
      const headers = { shopObjectId: this.shopObjectId};
      try {
        const response = await axios({
          url: `/api/v1.0/${uid}/productplu/check`,
          params: params,
          headers: headers,
          method: "GET",
        });
        if (response.data.error.code === 0) {
          return true;
        } else {
          this.invalidTransferModal = true;
          if (response.data.error.message === 'Destination site product not found.') {
              this.message = this.$t('destinationSiteProductNotFound');
            } else if (response.data.error.message === 'Invalid Status.') {
              this.message = this.$t('invalidStatus');
            } else if (response.data.error.message === 'Site Not Found.') {
              this.message = this.$t('siteNotFound');
            } else if (response.data.error.message === 'Document Already Closed') {
              this.message = this.$t('documentAlreadyClosed');
            } else if (response.data.error.message === 'Transfer to same site') {
              this.message = this.$t('transferToSameSite');
            } else if (response.data.error.message === 'Items Has Been Deleted. Or Quantity is 0') {
              this.message = this.$t('transferqtyis0');
            } else {
              this.message = response.data.error.message;
            }
          return false;
        }
      } catch (e) {
        this.alert = true;
        console.error(e);
        return false;
      }
    },
    getMinDate() {
      const today = new Date();
      today.setDate(today.getDate() + 1); // Set to tomorrow's date
      return today;
    },
    setDueDateToEndOfDay(date) {
      date.setHours(23, 59, 59, 999);
      return date;
    },
    getDateLocale() {
      if (this.$i18n.locale == "en") {
        return { lang: "en" };
      } else {
        return { lang: "de" };
      }
    },
    AddModal() {
      if (this.shopObjectId == this.destinationShop) {
        this.validatedata = this.$i18n.t("selectdestinationPlease");
      } else if (this.destinationShop == "") {
        this.validatedata = this.$i18n.t("selectdestinationPlease");
      } else {
        this.validatedata = "";
        this.all = false;
        this.AddProductModal = true;
      }
    },
    setAll() {
      let select = this.selectedItems;
      let data = this.dataProduct;
      // this.selectedItems = []
      if (this.all) {
        data.forEach(function (item) {
          item.selected = true;
          select.push(item);
        });
      } else {
        data.forEach(function (item) {
          item.selected = false;
          let i = select.findIndex((x) => x.objectId === item.objectId);
          if (i != -1) {
            select.splice(i, 1);
          }
        });
      }
      this.selectedItems = select;
      this.dataProduct = data;
    },
    setAddress() {
      let destinationShopDetail = this.destinationShopData.find(
        (i) => i.objectId === this.destinationShop
      );
      this.destinationShopDetail = destinationShopDetail;
    },
    onSelectedAutoCompleteEvent(item) {
      if (item && item !== null) {
      if (this.shopObjectId == this.destinationShop) {
        this.validatedata = this.$i18n.t("selectdestinationPlease");
      } else if (this.destinationShop == "") {
        this.validatedata = this.$i18n.t("selectdestinationPlease");
      } else {
        this.validatedata = "";
      }
        this.selectedItems.forEach((i) => {
          i.selected = false;
      });
      this.selectedItems = [];
    
      item.selected = !item.selected;
      if (item.selected) {
        this.selectedItems.push(item);
      } else {
        let i = this.selectedItems.findIndex((x) => x.objectId === item.objectId);
        if (i != -1) {
          this.selectedItems.splice(i, 1);
        }
      }
      let countItem = this.dataProduct.length;
      let coutselect = 0;
      this.dataProduct.forEach(function (item) {
        if (item.selected) {
          coutselect = coutselect + 1;
        }
      });
      if (countItem === coutselect) {
        this.all = true;
      } else {
        this.all = false;
      }
    } else {
      return ;
    }
    },
    reset() {
      this.keywordEntered = "";
      this.searchProductPLU();
    },
    searchProductPLU() {
      if (this.shopObjectId === "") {
        this.validateShop = this.$i18n.t("selectShopPlease");
      } else {
        this.validateShop = "";
      }
      const uid = `${localStorage.getItem("shopsUid")}`;
      let params = {};
      if (this.keywordEntered != "") {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          // searchBy: this.searchBy,
          keyword: this.keywordEntered,
        };
      } else {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          // searchBy: this.searchBy,
        };
      }
      this.label = this.searchBy;
      let selectedItems = this.selectedItems;
      // if (keywordEntered.length > 0) {
      const headers = { shopObjectId: this.$store.getters.shopObjectId };
      axios({
        url: "/api/v1.0/" + uid + "/getproductpluwithstock",
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((response) => {
          this.alert = false;
          let newData = [];
          response.data.data.forEach((item) => {
            if (selectedItems.length > 0) {
              let i = selectedItems.findIndex((x) => x.objectId === item.objectId);
              if (i != -1) {
                item.selected = true;
              } else {
                item.selected = false;
              }
            } else {
              item.selected = false;
            }
            const unit = item.unit || {};
            item.unitName = unit.name || "";
            item.indexColor = util.generateColor(item.ProductSKU.indexColor);
            if (item.selected == false) {
              newData.push(item);
            }
          });

          this.dataProduct = newData;
        })
        .catch((e) => {
          this.alert = true;
          console.log(e);
        });
      // }
    },
    customLabel(option) {
      return `${option.category.name} - ${option.PLUCode} - ${option.name}`;
    },
    removeProduct(i) {
      let items = this.items;
      let newItems = items.filter((item) => item.orderIndex != i.orderIndex);
      this.items = newItems;
      let index = this.autoCompleteResult.findIndex(
        (x) => x.objectId == i.productPLU.objectId
      );
      if (index != -1) {
        this.autoCompleteResult[index].selected = false;
      }

      let find = this.selectedItems.findIndex(
        (x) => x.objectId === i.productPLU.objectId
      );
      if (find != -1) {
        this.selectedItems.splice(i, 1);
      }
    },
    validateDoc() {
      if (this.shopObjectId == this.destinationShop) {
        this.validatedata = this.$i18n.t("selectdestinationPlease");
      } else if (this.destinationShop == "") {
        this.validatedata = this.$i18n.t("selectdestinationPlease");
      } else {
        if (this.items.length == 0) {
          this.validatedata = this.$i18n.t("validateProduct");
        } else {
          this.validatedata = "";
          this.confirmSave = true;
        }
      }
    },
    saveDocument() {
      this.dueDate = this.setDueDateToEndOfDay(new Date(this.dueDate));
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      this.destinationShopDetail = this.destinationShopData.find(
        (i) => i.objectId === this.destinationShop
      );
      const uid = `${localStorage.getItem("shopsUid")}`;
      let item = this.items;
      this.loadingButton = true;
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }
      let data = [];
      data = {
        datetime: this.datetime,
        dueDate: this.dueDate ,
        shop: {
          id: this.shop?.id,
          objectId: this.shop.objectId,
          shopName: this.shop.shopName,
          branchName: this.shop.branchName,
        },
        site: {
          id: this.destinationShopDetail?.id,
          objectId: this.destinationShopDetail.objectId,
          shopName: this.destinationShopDetail.shopName,
          branchName: this.destinationShopDetail.branchName,
        },
        items: item,
        note: this.note,
        stockDocType: 3,
        shopId: this.shop.id,
        uid: uid
      };
      local({
        method: "post",
        url: "/api/v1.0/" + uid + '/stockdocument/transfer/out',
        data: data,
        headers: headers
      })
        .then((res) => {
          this.loadingButton = false;
          this.confirmSave = false;
          if (res.data.error.code !== 0){
            this.invalidTransferModal = true
            if (res.data.error.message === 'Destination site product not found.') {
              this.message = this.$t('destinationSiteProductNotFound');
            } else if (res.data.error.message === 'Invalid Status.') {
              this.message = this.$t('invalidStatus');
            } else if (res.data.error.message === 'Site Not Found.') {
              this.message = this.$t('siteNotFound');
            } else if (res.data.error.message === 'Document Already Closed') {
              this.message = this.$t('documentAlreadyClosed');
            } else if (res.data.error.message === 'Transfer to same site') {
              this.message = this.$t('transferToSameSite');
            } else if (res.data.error.message === 'Items Has Been Deleted. Or Quantity is 0') {
              this.message = this.$t('transferqtyis0');
            } else {
              this.message = res.data.error.message;
            }
          }else{
           this.$router.push({
            name: 'TransferOut',
          })  
          }
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveDocumentStatusA() {
      this.dueDate = this.setDueDateToEndOfDay(new Date(this.dueDate));
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      this.destinationShopDetail = this.shops.find(
        (i) => i.objectId === this.destinationShop
      );
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }
      let item = this.items;
      this.loadingButton = true;
      let data = [];
      data = {
        datetime: this.datetime,
        dueDate: this.dueDate,
        shop: {
          id: this.shop.id,
          objectId: this.shop.objectId,
          shopName: this.shop.shopName,
          branchName: this.shop.branchName,
        },
        site: {
          id: this.destinationShopDetail.id,
          objectId: this.destinationShopDetail.objectId,
          shopName: this.destinationShopDetail.shopName,
          branchName: this.destinationShopDetail.branchName,
        },
        items: item,
        note: this.note,
        stockDocType: 3,
        shopId: this.shop.id,
        uid: uid,
        status: 'A'
      };
      local({
        method: "post",
        url: "/api/v1.0/" + uid + '/stockdocument/transfer/out',
        data: data,
        headers: headers
      })
        .then((res) => {
          this.loadingButton = false;
          this.confirmSave = false;
          if (res.data.error.code != 0){
            this.invalidTransferModal = true
            if (res.data.error.message === 'Destination site product not found.') {
              this.message = this.$t('destinationSiteProductNotFound');
            } else if (res.data.error.message === 'Invalid Status.') {
              this.message = this.$t('invalidStatus');
            } else if (res.data.error.message === 'Site Not Found.') {
              this.message = this.$t('siteNotFound');
            } else if (res.data.error.message === 'Document Already Closed') {
              this.message = this.$t('documentAlreadyClosed');
            } else if (res.data.error.message === 'Transfer to same site') {
              this.message = this.$t('transferToSameSite');
            } else if (res.data.error.message === 'Items Has Been Deleted. Or Quantity is 0') {
              this.message = this.$t('transferqtyis0');
            } else {
              this.message = res.data.error.message;
            }
          }else{
           this.$router.push({
            name: 'TransferOut',
          })  
          }
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async onCheckboxChange() {
      let data = this.selectedItems;
      let items = this.items;
      let select = [];
      let isshowAlert = 0;

      for (let index = 0; index < data.length; index++) {
        const item = data[index];

        if (item.ProductPLUStock.onhandQty > 0) {
          const isValid = await this.checkItemDestination(item);

          if (isValid) {
            if (items.length < 200) {
              let stdCost = 0;
              let SKURatio = 1;
              let previousOnhand = 0;
              if (item.selected) {
                select.push(item);
                if (
                  item.ProductSKU.stdCost === undefined ||
                  item.ProductSKU.stdCost === null
                ) {
                  stdCost = 0;
                } else {
                  stdCost = item.ProductSKU.stdCost;
                }

                if (item.SKURatio == undefined) {
                  SKURatio = 1;
                } else {
                  SKURatio = item.SKURatio;
                }

                if (item.ProductPLUStock.onhandQty == undefined) {
                  previousOnhand = 0;
                } else {
                  previousOnhand = (item.ProductPLUStock.onhandQty).toFixed(4);
                }
                if (items.length > 0) {
                  let i = items.findIndex((x) => x.productPLU.objectId === item.objectId);
                  if (i == -1) {
                    items.push({
                      orderIndex: index,
                      productPLU: {
                        id: item.id,
                        objectId: item.objectId,
                        PLUCode: item.PLUCode,
                        name: item.name,
                        unit: item.unit,
                        SKURatio: SKURatio,
                      },
                      productPLUId: item.id,
                      quantity: 0,
                      productSKUObjectId: item.ProductSKU.objectId,
                      productSKU: item.ProductSKU || {},
                      remoteImagePath: item.remoteImagePath,
                      indexColor: item.indexColor,
                      vatType: item.ProductSKU.vatType,
                      previousOnhand: parseFloat(previousOnhand),
                    });
                  }
                } else {
                  items.push({
                    orderIndex: index,
                    productPLU: {
                      id: item.id,
                      objectId: item.objectId,
                      PLUCode: item.PLUCode,
                      name: item.name,
                      unit: item.unit,
                      SKURatio: SKURatio,
                    },
                    productPLUId: item.id,
                    quantity: 0,
                    productSKUObjectId: item.ProductSKU.objectId,
                    productSKU: item.ProductSKU || {},
                    remoteImagePath: item.remoteImagePath,
                    indexColor: item.indexColor,
                    vatType: item.ProductSKU.vatType,
                    previousOnhand: parseFloat(previousOnhand),
                  });
                }
              } else {
                let index = items.findIndex((x) => x.productPLU.objectId === item.objectId);
                if (index != -1) {
                  items.splice(index, 1);
                }
              }
            } else {
              isshowAlert = 5;
            }
          }
        } else {
          this.onhandIncorrectModal = true;
          item.selected = false;
        }
      }

      this.isshowAlert = isshowAlert;
      this.items = items;
      this.selectedItems = select;
      this.AddProductModal = false;
      this.all = false;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect__tags {
  min-height: 35px !important;
  height: 35px !important;
  padding-top: 6px !important;
}
.category-name {
  color: #29b289;
}
.multiselect__option:hover .category-name {
  color: #fff; 
}
.multiselect__option--highlight .category-name {
  color: #fff;
}
.multiselect__input, .multiselect__single .category-name {
  color: #3abc98; 
}
</style>